<template>
  <div class="allpage">
    <div class="page-content" id="content">
      <div id="lightgalleryid"></div>
    </div>
  </div>
</template>

<script>

import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgHash from 'lightgallery/plugins/hash';
import lgShare from 'lightgallery/plugins/share';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgRotate from 'lightgallery/plugins/rotate';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
// import videojs from 'video.js'
// import "video.js/dist/video-js.css";

import {mapGetters} from "vuex";
let dynGallery = null;
export default {
  props: [
    'category',
  ],
  computed: {
    ...mapGetters({
      sortType: 'category/sortType',
      sortDirection: 'category/sortDirection',
      elements: 'category/elements',
    }),
  },
    data: () => ({
      dynamicElements: [],
      backClicked: false,
    }),
    async mounted() {
      // const plugin = document.createElement("script");
      // plugin.setAttribute(
      //     "src",
      //     "https://vjs.zencdn.net/7.20.3/video.min.js"
      // );
      // plugin.async = true;
      // document.head.appendChild(plugin);

      let actualIndex = await this.loadCategories()
      dynGallery = await lightGallery(document.getElementById('lightgalleryid'), {
        dynamic: 'true',
        plugins: [lgZoom, lgVideo, lgHash, lgShare, lgAutoplay, lgRotate, lgFullscreen],
        dynamicEl: [],
        speed: 300,
        thumbnail: false,
        allowMediaOverlap: false,
        animateThumb: true,
        alignThumbnails: 'middle',
        enableZoomAfter: 300,
        hash: false,
        autoplayFirstVideo: false,
        autoplayVideoOnSlide: false,
        gotoNextSlideOnVideoEnd: false,
        mode: 'lg-scale-up',
      });
      document.getElementById('lightgalleryid').addEventListener('lgBeforeClose', () => {
        if (!this.backClicked)
          this.$router.back()
      });
      window.addEventListener('popstate', () => {
        this.backClicked = true;
        top.location.reload();
      });
      dynGallery.refresh(this.dynamicElements)
      dynGallery.openGallery(actualIndex)
      // Array.from(document.getElementsByClassName("component-content")).forEach(
      //     function(element, index, array) {
      //       element.style.maxWidth = 'none'
      //       // element.style.maxHeight = 'calc(100% - 80px)'
      //     }
      // );
      // Array.from(document.getElementsByClassName("current-category")).forEach(
      //     function(element, index, array) {
      //       element.style.display = 'none'
      //     }
      // );
    },
  methods: {
      async loadCategories() {
        // let z = this.category.pathids[this.category.pathids.length - 2]
        // this.elements = await this.$api.findMediaCategories(z, this.sortType, this.sortDirection)
        let actual = 0;
        let j = 0;
        this.dynamicElements = [];
        for (let i = 0; i < this.elements.length; i++) {
          if (this.elements[i].media) {
            if (this.elements[i].media.mediatype === 'image') {
              const media = this.elements[i]?.media?.links?.find(l => l.rel === 'preview')
              const download = media.href + "&d=" + this.elements[i].categoryname;
              const thumbnail = this.elements[i].links.find(link => link.rel === 'thumbnail').href + '&f=nf'
              const title = '<div class="lightGallery-captions"><h1>' + this.elements[i].categoryname + '</h1></div>'
              let myo = {src: media.href, thumb: thumbnail, subHtml: title, downloadUrl: download}
              this.dynamicElements[j] = myo
            } else if (this.elements[i].media.mediatype === 'video') {
              const media = this.elements[i]?.media?.links?.find(l => l.rel === 'preview')
              const download = media.href + "&d=" + this.elements[i].categoryname;
              const poster = this.elements[i]?.media?.links?.find(l => l.rel === 'poster')
              const thumbnail = this.elements[i].links.find(link => link.rel === 'thumbnail').href + '&f=nf'
              const title = '<div class="lightGallery-captions"><h1>' + this.elements[i].categoryname + '</h1></div>'
              let myo = {video: {"source": [{"src":media.href, "type":"video/mp4"}], "attributes": {"preload": false, "controls": true}}, poster: poster.href, thumb: thumbnail, subHtml: title, downloadUrl: download}
              this.dynamicElements[j] = myo
            } else if (this.elements[i].media.mediatype === 'audio') {
              const media = this.elements[i]?.media?.links?.find(l => l.rel === 'preview')
              const download = media.href + "&d=" + this.elements[i].categoryname;
              const thumbnail = this.elements[i].links.find(link => link.rel === 'thumbnail').href + '&f=nf'
              const title = '<div class="lightGallery-captions"><h1>' + this.elements[i].categoryname + '</h1></div>'
              let myo = {video: {"source": [{"src":media.href, "type":"audio/mp4"}], "attributes": {"preload": false, "controls": true}}, thumb: thumbnail, subHtml: title, downloadUrl: download}
              this.dynamicElements[j] = myo
          } else continue;
            if (this.category.id === this.elements[i].id) {
              actual = j;
            }
            j++;
          }
        }
        return actual;
      },

    },
inject: [
  '$api',
  '$helper']
}

</script>

<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lg-autoplay.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lg-fullscreen.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lg-medium-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lg-relative-caption.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lg-rotate.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lg-share.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lg-transitions.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lg-video.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.7.0/css/lg-zoom.css');
</style>

<style lang="scss" scoped>
.allpage {
  @apply
  md:pl-0
  md:pt-0
  md:flex
  md:w-full
  md:h-full
  md:flex-col;

  .page-content {
    @apply
    flex
    w-full
    h-full
    overflow-y-auto;
  }
}
</style>

